import React from "react";
import "../components.css";

const ProjectHeroPanel = ({
    image,
    label1, 
    label2, 
    label3,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9
    }) => {
    return(
        <div className="section no-top-space">
        <div className="container grid-container grid-direction-vertical">
            
            <div className="panel grid-image-container">
                <img src={require(`../Images/projectHeroPanel/${image}`)}  alt="" className="grid-image-cover"/>
            </div>

            <div className="layout-grid grid-thirds">
                <div className="panel">
                    <div className="panel-body flex-start">
                        <h6 className="no-bottom-space">{label1}</h6>
                        <ul>
                            <li>{text1}</li>
                            <li>{text2}</li>
                            <li>{text3}</li>
                        </ul>
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body flex-start">
                        <h6 className="no-bottom-space">{label2}</h6>
                        <ul>
                            <li>{text4}</li>
                            <li>{text5}</li>
                            <li>{text6}</li>
                        </ul>
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-body flex-start">
                        <h6 className="no-bottom-space">{label3}</h6>
                        <ul>
                            <li>{text7}</li>
                            <li>{text8}</li>
                            <li>{text9}</li>
                        </ul>
                    </div>
                </div>    
            </div>           
            </div>
        </div>
        
    );
};

export default ProjectHeroPanel;
