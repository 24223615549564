import React from 'react';
import { Link } from 'react-router-dom'; 
import './Nav.css'; 
import "../components.css";
import {motion} from 'framer-motion';
import logoImage from '../Images/60x60.png';
import emailIcon from '../Images/email.png';
import githubIcon from '../Images/github.png';
import linkedinIcon from '../Images/linkedin.png';


const Nav = () => {
  
  return (
    <motion.div 
    initial={{ y:-20, opacity: 0 }}
    animate={{ y:0, opacity: 1 }}
    transition={{  ease:"easeOut", duration:0.5 }} className='container '> 
      <nav>
        <div className='div-block'>
          <div className='nav-row'>
            
            <a href="/" aria-current="page" className="" aria-label="home">
              <img src={logoImage} width="auto" height="auto" alt="web-logo" className="nav-logo scale150" />
            </a>
            <a href="/" aria-current="page" aria-label="home">
              <h4 className='nav-adjusttitle'>Abhijeet Saraf</h4>  
            </a>
            <div className='nav-menu'>
              <Link className="text-link nav-menu-links" to="/">Home</Link>
              <Link className="text-link nav-menu-links" to="/gimmick">Gimmick</Link>
              <Link className="text-link nav-menu-links" target='blank' to="https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link">Resume</Link>
              <Link className="text-link nav-menu-links" to="/aboutme">About Me</Link>
              <a href="mailto:abhi.saraf25@gmail.com" target='blank' aria-current="page" className="" aria-label="email">
                <img src={emailIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
              </a>
              <a href="https://github.com/abhijeetsaraf" target='blank' aria-current="page" className="" aria-label="github">
                <img src={githubIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
              </a>
              <a href="https://www.linkedin.com/in/abhijeet-saraf/" target='blank' aria-current="page" className="" aria-label="linkedin">
                <img src={linkedinIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
              </a>
            </div>    
          </div>
        </div>      
      </nav>
    </motion.div>
  );
};

export default Nav;