import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";

function Vocalamp(){
    
        return(
            <div> 
                <ScrollToTopButton/>
                <ProjectHeroPanel
                    image="vocalamp-banner.png"
                    label1="Key contributions"
                    label2="Team"
                    label3="Duaration"
                    text1="Distillation of literature for connected devices technology.                    "
                    text2="Form factor design of smart lamp in Fusion 360, and rapid prototyping of mobile app using Figma."
                    text3="Ambient sensor interfacing on RPi4 using Python."
                    text4="Hardware and Software Designer/Developer"
                    text5="Product Designer"
                    text6="User Researcher"
                    text7="3 Months"
                    text8=""
                    text9=""
                ></ProjectHeroPanel>

                {/* Overview Section of the project */}
                <div className="section no-bottom-space">
                    <div className="container content-width-extra-large">
                        <h5>Overview</h5>
                        <h2>Vocalamp</h2>
                        <h3>VocaLamp is a multi-feature smart lamp that improves the reading experience of print story books for young children by providing various vocabulary learning techniques with the use of IoT.</h3>
                        <p>Print books are proven to be crucial to reading comprehension for young children as compared to digital books. The child can point the finger at the word and can learn about the new words they encounter.</p>
                    </div>
                </div>
                
                {/* Mobile Prototype and Image */}
                <div className="section no-top-space no-bottom-space">
                    <div className="container content-width-extra-large">
                        <div className="layout-grid grid-halves">
                            <div className="panel scale70">
                                <img src={require(`../Images/vocalamp/mobile-compressed-gif.gif`)}  alt="" className="grid-image"/>
                            </div>
                            <div>
                                <div className="panel-body justify-items-center">
                                    <div>
                                        <h5>Vocalamp application for Parents</h5>
                                        <h3>Lamp controls and Vocabulary</h3>
                                        <p>Seamlessly track the progress of your child while you are away.</p>
                                        <p>Discuss their learnings of the day through new words they had learnt.</p>
                                    </div>                          
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                

                {/* Timeline */}
                <div className="section no-top-space">
                    <div className="container content-width-extra-large">
                        <h5>Timeline</h5>                     
                    </div>
                    <div className="container content-width-extra-large space-bottom-extra-large">
                        <img src={require(`../Images/vocalamp/web-timeline.png`)}  alt="" className="rounded-image scale120"/>   
                    </div> 
                </div>

                {/* Solution */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Solution</h5>
                        <h3>Lamp Design and features</h3>
                        <ul className="space-bottom-large">
                            <li>The Lamp helps children learn new words while reading physical books by using a camera to process and recognize words through gesture detection.</li>
                            <li>The word is parsed and feedback is given to the child as pronunciation and meaning through a speaker, utilizing dictionary and speech APIs.</li>
                            <li>The Lamp also provides visual feedback through LED lights, creating immersive lighting conditions.</li>
                            <li>There is also a mobile app available for parents to track the words their child learns throughout the day, as well as a snapshot of the ideal lighting conditions for the study desk.</li>
                        </ul>                     
                    </div>
                    {/* Lamp Image */}
                    <div className="container content-width-extra-large space-bottom-extra-large">
                        <img src={require(`../Images/vocalamp/lamp-design.jpg`)}  alt="" className="rounded-image"/>   
                    </div> 
                    {/* Process Image */}
                    <div className="container content-width-extra-large space-bottom-extra-large">
                        <img src={require(`../Images/vocalamp/web-working-diagram.png`)}  alt="" className="rounded-image"/>   
                    </div> 
                </div>

                {/* Hardware */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Hardware</h5>                     
                        <h3>Custom PCB board, interfacing with Raspberry Pi 4/ TSL2591 High Dynamic Range Digital Light Sensor</h3>
                    </div>
                    <div className="container content-width-extra-large space-bottom-extra-medium">
                        
                        <img src={require(`../Images/vocalamp/image-grid.png`)}  alt="" className="rounded-image"/>
                        
                        {/* <div className="layout-grid grid-halves space-bottom-large">
                            <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" className="rounded-image"/>
                            <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" className="rounded-image"/>      
                        </div>
                        <div className="layout-grid grid-halves">
                            <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" className="rounded-image"/>
                            <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" className="rounded-image"/>      
                        </div> */}
                    </div> 
                </div>

                {/* Reflections */}
                <div className="panel panel-body content-width-extra-large">
                    <div className="space-bottom-medium">
                        <h5 className="space-bottom-large">Reflections</h5>
                        <h4 className="no-bottom-space">All about IoT</h4>
                        <ul>
                            <li>Only during full-scale product development, one can understand development challenges on the hardware front and their close integration with software. We could achieve a full loop of a capable prototype that we tested with some kids and found them in amazement with the features.</li>
                        </ul>
                        <h4 className="no-bottom-space">What went well?</h4>
                        <ul>
                            <li>A centralized product development strategy and a dedicated researcher kept us on the right mid-set to develop the product. We started with digital bookmarks for efficient reading moved to projection technologies and eventually constructed a smart lamp. All because of rigorous literature reviews.</li>
                        </ul>
                    </div>
                </div>

                {/* Further Details */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Further details</h5>
                        <br></br>
                        <h3>The lamp underwent several iterations to determine its form factor and the optimal placement of sensors. I would like to provide a full tour of this innovative IoT product.</h3>
                        <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                    </div>
                </div>
            </div>
        )
    }

export default Vocalamp;