import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";

function WackAMole(){
    
        return(
            <div>
                <ScrollToTopButton/>
                <ProjectHeroPanel
                    image="whack-a-cover.png"
                    label1="Key contributions"
                    label2="Team"
                    label3="Duaration"
                    text1="Generated innovative ideas around dynamic board games and solving issues around cognitive abilities."
                    text2="Hardware prototyping and design in Fusion 360."
                    text3="Interfaced PPG sensor using Arduino."
                    text4="‍Hardware Prototyper"
                    text5="2 Software developer and ML Engineer"
                    text6="User researcher"
                    text7="3 Months"                    
                    text8=""
                    text9=""
                >
    
                </ProjectHeroPanel>

                {/* Overview Section of the project */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Overview</h5>
                        <h2>Whack-a-mole</h2>
                        <h3>Gamified cognitive assessment through dynamic board game.</h3>
                        <p>Print books are proven to be crucial to reading comprehension for young children as compared to digital books. The child can point the finger at the word and can learn about the new words they encounter.</p>
                        <ul>
                            <li>Memory</li>
                            <li>Reaction Speed</li>
                            <li>Accuracy</li>
                            <li>Heartrate</li>
                        </ul>
                    </div>
                </div>


                {/* Problem Statement */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Problem Statement</h5>
                        <h3>Current issues with traditional cognitive assessment</h3>
                        <ul>
                            <li>Cognitive assessment are boring and unengaging.</li>
                            <li>Digitizing cognitive assessment and allowing people to test for potential cognitive disorder.</li>
                            <li>Existing research points to Game based cognitive assessment and measure cognitive levels through reaction speed, memory based games.</li>
                        </ul>
                    </div>
                </div>

                {/* Solution */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Solution</h5>
                        <h3>Whack-a-mole as a dynamic board game</h3>
                        <h4 >The game features three levels of pattern recognition, created by the LED and parallel processing of the heart rate during gameplay.</h4>
                    </div>
                    {/* Lamp Image */}
                    <div className="container content-width-extra-large space-bottom-extra-large">
                        <img src={require(`../Images/whackamole/whackamole.jpeg`)}  alt="" className="rounded-image"/>   
                    </div> 
                </div>

                {/* Further Details */}
                <div className="section">
                    <div className="container content-width-extra-large">
                        <h5>Further details</h5>
                        <br></br>
                        <h3>The underlying hardware is a simple LED grid, whereas a lot more goes into the ML pipeline for the game to function well. This is a POC and I would like to tell more stories that underwent from research to gameboard and its limitations.</h3>
                        <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                    </div>
                </div>

            </div>
        )
    }


    export default WackAMole;