import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";


const designSystemOverview = [
    { src: require(`../Images/opportunity/web-text-components.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/web-color-components.png`), alt: 'Image 2' },
    { src: require(`../Images/opportunity/web-components.png`), alt: 'Image 3' },
];

const offeringWireframes = [
    { src: require(`../Images/opportunity/offering-wireframes-1.jpg`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/offering-wireframes-2.jpg`), alt: 'Image 2' },
    { src: require(`../Images/opportunity/offering-wireframes-3.jpg`), alt: 'Image 3' },
    { src: require(`../Images/opportunity/offering-wireframes-4.jpg`), alt: 'Image 4' },
    { src: require(`../Images/opportunity/offering-wireframes-5.jpg`), alt: 'Image 5' },
    { src: require(`../Images/opportunity/offering-wireframes-6.jpg`), alt: 'Image 6' },
    { src: require(`../Images/opportunity/offering-wireframes-7.jpg`), alt: 'Image 7' },
    { src: require(`../Images/opportunity/offering-wireframes-8.jpg`), alt: 'Image 8' },
    { src: require(`../Images/opportunity/offering-wireframes-9.jpg`), alt: 'Image 9' },
    { src: require(`../Images/opportunity/offering-wireframes-10.jpg`), alt: 'Image 10' },
    { src: require(`../Images/opportunity/offering-wireframes-11.jpg`), alt: 'Image 11' },
];

const sketches = [
    { src: require(`../Images/opportunity/sketches-1.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-2.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-3.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-4.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-5.png`), alt: 'Image 1' },
];

    
function Smartsales() {

    return(
        <div>              
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="opportunity-tile-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Research with key stakeholders Sales enablement teams, product managers."
                text2="Wireframing and usability testing of hypotheses and delivery of mock-ups."
                text3="Establishing and implementing design system and mentoring 2 junior designers."
                text4="2 Product Manager, 2 Business Analysts"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, 2 Visual Designer"
                text7="Phase 1 2018-2019"
                text8="Phase 2 2020-2021"
                text9="Phase 3 2020-2021">
            </ProjectHeroPanel> 

            {/* Overview Section of the project */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Overview</h5>
                    <h2>TCS SmartSales Opportunity</h2>
                    <p>In 2018, TCS Internal decided to retire Oracle's Siebel CRM and build their own CRM tool. As part of the team, I participated right from database disintegration and led the design of two core modules: Opportunity & Insights. Additionally, I guided a parallel team in designing a mobile platform for quick updates.</p>
                    <h3>Impact</h3>
                    <div className="panel bg-gradient-4">
                        <div className="panel-body ">
                            <div className="grid-thirds">
                                <h2>+3000</h2>
                                <h2>70%</h2>
                                <h2>+$15 Bn</h2>
                                <h5>Sales users onboarded on new CRM platform</h5>
                                <h5>Reduction in CRM expense at TCS</h5>
                                <h5>Worth of deals managed on SmartSales CRM</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Interface Screens for Users */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>CRM Interface</h5>
                    <h3>Dashboard - Sales performace</h3>
                    <p>Landing page: Personas addressed: Business Relationship Managers, Business Development Managers, and teams</p>
                    <p></p>
                </div>    
                <div className="container content-width-extra-large space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" className="rounded-image"/>   
                </div>    
                
                <div className="container content-width-extra-large">
                    <h3>Opportunity Details</h3>
                    <p className="space-bottom-large">Details view of an opportunity - Overview of the deal timeline. Users monitor deals’ progress and add details under respective sections.  Collaborative space for Sales team to work on the leads.</p>
                </div>
                <div className="container content-width-extra-large space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-opportunity-deatils-page-1.jpg`)}  alt="" className="rounded-image"/> 
                </div>    
                <div className="container content-width-extra-large">
                    <h3>Mobile Application</h3>
                    <p className="space-bottom-large">Opportunity mobile app is a unique blend of curated features for users while they are on the move, the app allows easy monitoring, quick notes, create teams, and helps in negotiation.</p>
                </div>
                
                <div className="container content-width-extra-large grid-container">
                {/* Mobile Screens  */}
                    <div className="layout-grid grid-thirds">
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/opportunity/mobile-dashboard.png`)}  alt="" className="rounded-image"/>   
                                <div className="panel panel-body space-top-medium">
                                    <p className="white">Dashboard provides easy monitoring of the opportunity performance.</p>
                                </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/opportunity/mobile-opportunities-details.png`)}  alt="y" className="rounded-image"/>   
                                <div className="panel panel-body space-top-medium">
                                    <p className="white">Opportunity detailsallows easy manipulation of various status and stages.</p>
                                </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/opportunity/mobile-offerings.png`)}  alt="" className="rounded-image"/>   
                                <div className="panel panel-body space-top-medium">
                                    <p className="white">Offering details helps you negotiate easily with clients and change in data.</p>
                                </div>
                        </div>
                    </div>
                </div>               
            </div>

            {/* Business Goals and Proposition */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Business goals and proposition</h5>
                    <h3>Leverage data, build internal capability & cost cuts on software expenditure</h3>
                    <h4>Challenges in current tool</h4>
                    <ul>
                        <li>Lack of user-centered design and aesthetics.</li>
                        <li>Overlapping functionalities</li>
                        <li>Obsolete features and bulky tool</li>
                    </ul>
                    <p>Interface snapshot of Oracle Siebel</p>
                    <img src={require(`../Images/opportunity/web-siebel-interface.png`)}  alt="" className="rounded-image space-bottom-large"/>   
                    <div className="panel panel-body bg-gradient-4 space-bottom">
                        <h5>Design Question</h5>
                        <h3>How can we create a toolset to foster accountability towards TCS customer needs, enhance collaboration in teams and provide curated features to improve productivity?</h3>
                    </div>
                </div>
            </div>
            
            {/* User Research */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Research Outline</h5>
                    <h3>Divided research into following phases</h3>
                    <br></br> 
                    <img src={require(`../Images/opportunity/web-research-timeline.png`)}  alt="" className="rounded-image space-bottom-extra-large scale120"/>                      
                    <h3>Journey Map</h3>
                    <h4>Addressed following personas</h4>
                    <p className="white">Business development managers, Business relationship managers, Pre Sales teams, and Business and technology unit.</p>
                    <p>The core understanding from the map was an understanding of users’ frequency to interact with opportunities and core tasks in specific phases.</p>
                    <img src={require(`../Images/opportunity/web-journey-map.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>   

                    {/* Pain Points and Emerging Design Requirements */}
                    <div className="panel panel-body content-width-extra-large">
                        <div className="space-bottom">
                            <h4>Pain Points</h4>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">Hard to determine the exact positioning of the opportunity and actions taken on opportunity progress.</li>
                                <li className="bordered-list-item-small">Delays in initiating parallel workflows for workforce management(HR) and contract management(Legal).</li>
                                <li className="bordered-list-item-small">Extraneous processes and a bulky interface and lacks aesthetic appeal.</li>
                            </ul>
                        </div>
                        <div className="space-bottom">
                            <h4>Emerging design requirements</h4>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">An easy method to track opportunity progress that fits sales needs.</li>
                                <li className="bordered-list-item-small">Clear display of opportunity progress and upcoming major events.</li>
                                <li className="bordered-list-item-small">Option to initiate parallel workflows for sales teams.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Solutioning */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Solutioning</h5>                    
                    <h3>Sketches reflecting pain-points</h3>
                    <h4>Features for determining opportunity progress, products, and workflows for HR and Legal.</h4>
                </div>
                {/* <div className="container space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-wireframe-group-2.png`)}  alt="" className="rounded-image"/>   
                </div>   */}
                <ImageSwiper images={sketches} />     
                <div className="container content-width-extra-large">
                    <h3 className="space-top-extra-large">Low-fidelity wireframes</h3>
                </div>    
                {/* <div className="container space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-low-fi-wireframes-1.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-low-fi-wireframes-2.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>
                </div>    */}
                <ImageSwiper images={offeringWireframes} />          
                <div className="container content-width-extra-large">
                <h3 className="space-top-extra-large">Comparison of task-flows from legacy app to SmartSales - Opportunity</h3>
                    <h4>Key differentiators</h4>
                    <ul>
                        <li>Redesigned task flows considering the user inputs from various personas in the opportunity lifecycle.</li>
                        <li>Wherein old task flow was ideal if the users were trained on the foundations of using the system, SmartSales was calibrated with minimal features and didn't require explicit training.</li>
                        <li>The task flow was optimized to cater to future requirements for scalability.</li>
                    </ul> 
                </div>        
                <div className="container space-bottom-extra-large space-top-medium">
                    <img src={require(`../Images/opportunity/web-task-flows-old-new.png`)}  alt="" className="rounded-image "/>           
                </div>    
            </div>

            {/* Design System */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Design System</h5>                    
                    <h3>Glimpse of reusable pattern library with tokens</h3>
                </div>    
                {/* <div className="container space-bottom-extra-large space-top-medium">
                    <img src={require(`../Images/opportunity/web-text-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-color-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                </div>  */}
                <ImageSwiper images={designSystemOverview} />
            </div>

            {/* Further details */}
            <div className="section">
                <div className="container content-width-extra-large">                    
                    <h5>Further details</h5>
                    <h3>Numerous stories to share for subsequent phases delivering automation, checkout flow and extending design system.</h3>
                    <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>

        </div>
    );
};
      

export default Smartsales;