import React from "react";
import PropTypes from "prop-types";
import "../components.css";

//Receive another prop called badge color

const Badge = ({label, badgeColor}) => {
    
    const badgeClass = `badge ${badgeColor}`;
    return(
        <div>
            <span className={badgeClass}>{label}</span>
        </div>
    );
}

Badge.propTypes = {
    badgeColor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Badge;