import React from "react";
import Button from "../Button";
import "../components.css";
import Badge from "../Badge/Badge";
import { useNavigate } from "react-router-dom";

const ProjectCards = ({id,image, panelSubheading, cardHeading, cardSubheading, badgesArray, secureStatus, openModal}) => {    
    
    const navigate = useNavigate();
    const handleCardClick = (projectId) => {
        if(secureStatus){
            openModal(id);
        }else{
            navigate(`/project/${projectId}`);    
        }

        };

    
    return(
        <div className="section">
            <div key={(`${cardHeading}`)} className="container grid-container" >
                <div className="layout-grid grid-halves">
                    <div className="panel">
                        <img src={require(`../Images/${image}`)}  alt="" className="grid-image-cover"/>
                    </div> 
                    <div className="panel">
                        <div className="panel-body">
                            <div className="extend-elements-vertical">
                                {/* Card Details */}
                                <div>
                                    <h5>{panelSubheading}</h5>
                                    <h3>{cardHeading}</h3>
                                    <h3 className="text-gray-4">{cardSubheading}</h3>
                                    <div className="container-badge">{badgesArray.map((badge) => (
                                            <Badge label={badge} badgeColor={"primary-4"}/>
                                        ))}
                                    </div>
                                </div>
                                {/* Button Details */}
                                <div>
                                    <div className="button-row">
                                        <Button size="lg" label="View Designs" onClick={() => handleCardClick(id)} className="primary-4" secure={secureStatus}/>    
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCards;

