import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";
import { Link } from "react-router-dom";

const componentOverview = [
    { src: require(`../Images/optum/web-interaction-collection-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/web-interaction-collection-2.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/web-interaction-collection-3.png`), alt: 'Image 3' },
];

const componentGroup = [
    { src: require(`../Images/optum/text-inputs-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/multi-inputs-1.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/multi-inputs-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/toggle.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-1.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-3.png`), alt: 'Image 3' },
];

function Optum(){
    
    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="optum-housecalls.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Designing Medication, Diagnosis, Review of Systems Sections in Figma"
                text2="Modularizing/Contributing to component utilization and design for Optum Design Systems - Netra"
                text3="Developer assistance in implementing components via storybook guidance"
                text4="Associate Director of Nursing, 4 Technical Project Manger"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, UX Designer"
                text7="HouseCalls - May 2022 - Dec 2023"
                text8=""
                text9=""
            >
            </ProjectHeroPanel>

            
            {/* ----------------------------------------------Overview Section of the project----------------------------------------- */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Overview</h5>
                    <h2>HouseCalls</h2>
                    <p>HouseCalls is an annual in-home clinical assessment for members of participating health plans.
                        The assessment can improve performance on key Star measures. A HouseCalls visit results in a
                        more complete and accurate identification of a member’s health conditions.</p>
                    <Link className="text-link nav-menu-links underline-text" target='blank' to="https://www.optum.com/en/business/health-plans/members/in-home-clinical-assessment.html">Read more about Optum - HouseCalls</Link>
                    <h3 className="space-top-large">Impact</h3>
                    <div className="panel bg-gradient-8 space-top-small">
                        <div className="panel-body ">
                            <h5>Factsheet</h5>
                            <div className="grid-thirds">
                                <h2>2.1M</h2>
                                <h2>86%</h2>
                                <h2>99%</h2>
                                <h5>Visits including 61K virtual visits</h5>
                                <h5>Star gap closure rate</h5>
                                <h5>Member satisfaction</h5>
                            </div>
                            <div className="grid-thirds space-bottom-medium no-bottom-space">
                                <h2>50</h2>
                                <h2>91%</h2>
                                <h2>95%</h2>
                                <h5>States where HouseCalls <br/>is available</h5>
                                <h5>Repeat visit <br/>acceptance rate</h5>
                                <h5>Coding Accuracy</h5>
                            </div>
                        </div>
                    </div>
                </div>
                
                    
                    
             <div className="section">
                <div className="container content-width-extra-large">
                    <h5>HouseCalls Interface</h5>
                    <h3 className="space-top-large">Designed Medication and Review of Systems sections.</h3>
                    <div>
                        <img src={require(`../Images/optum/project-hero.png`)}  alt="" className="rounded-image"/>    
                    </div>
                </div>
                <div className="container content-width-extra-large"> 
                    {/* Unordered List  */}
                    <div className="panel panel-body content-width-extra-large space-top-medium">
                        <div className="space-bottom-small">
                            <h5>Context</h5>
                            <h3 >HouseCalls</h3>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small"><span className="white">HouseCalls provides in-home visits for members (45 -60 mins)</span> and works with health plans to address undiagnosed health conditions, aiming to improve outcomes and reduce costs.</li>
                                <li className="bordered-list-item-small">Members receive personalized attention from Advanced Practice Clinicians,<span className="white"> including a comprehensive exam, medication review, and health screenings</span> , all within the comfort of their own homes.</li>
                                <li className="bordered-list-item-small">The program offers<span className="white"> educational materials and resources tailored to each member's healthcare needs</span>, promoting better understanding and management of their health.</li>
                            </ul>
                        </div>
                    </div>

                    {/* My Contributions */}
                    <h5 className="space-top-extra-large">Design Contributions</h5>
                    <div className="layout-grid grid-thirds grid-direction-vertical-auto">
                        <div className="panel panel-body bg-gradient-4 space-top-medium">
                            <h5>Design of Active/Inactive medication user flows</h5>
                        </div>
                        <div className="panel panel-body bg-gradient-4 space-top-medium">
                            <h5>Interaction patterns for Review of Systems forms</h5>
                        </div>
                        <div className="panel panel-body bg-gradient-4 space-top-medium">
                            <h5>Modularizing component structure with Dev teams</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Medication</h5>
                    <h3>Design of Active/Inactive medication user flows</h3>
                    <p>For a comprehensive medication overview, 
                        all the data related to medication 
                        consumption must be updated for better
                         inference and major outcomes of the APCs visit.
                    </p>
                </div>

                {/* Research */}
                <div className="container content-width-extra-large">
                    {/* Unordered List  */}
                    <div className="panel panel-body content-width-extra-large space-top-medium">
                        <div className="space-bottom-small">
                            <h4>Research questions for APCs Interview</h4>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">What is <span className="white">Medication process</span> in context of house visits?</li>
                                <li className="bordered-list-item-small">What are the <span className="white">outliers</span> in and data inconsistencies in current records?</li>
                                <li className="bordered-list-item-small">What are <span className="white">other pages of application</span> where medication data is pivotal?</li>
                                <li className="bordered-list-item-small">Would users have a <span className="white">shared view</span> of interface while visit?</li>
                            </ul>
                        </div>
                    </div>

                    {/* Unordered List  */}
                    <div className="panel panel-body content-width-extra-large space-top-medium">
                        <div className="space-bottom-small">
                            <h4>Interview Outcomes</h4>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">While collecting medication information members might provide false data, they may be consuming over-the-counter available medication, or might be taking herbal medication.</li>
                                <li className="bordered-list-item-small">A recent hospitalization will require APC to gather complete information on the medication completed by the members, this can impact regular medication records.</li>
                                <li className="bordered-list-item-small">Medication data include quantity, time, frequency, last fill data, Non-adherence to medication, Active/Inactive, and more.</li>
                                <li className="bordered-list-item-small">Apart from collecting medication APCs are obligated to update any past pending or missing record for medication. </li>
                                <li className="bordered-list-item-small">Outlier scenarios can include caregiver not present, member diagonsed with Dimentia/Alzhemiers, original bottles not available.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Outcomes and Interface Suggestions */}
                <div className="container content-width-extra-large">
                    <h3 className="space-top-large">Outcomes and Interaction Suggestions</h3>
                    
                    {/* Images and Information Panel */}
                    <div className="layout-grid grid-two-thirds space-top-small">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <h4>Wireframes</h4>
                                    <p>Ideated 3 versions to segrate data and presented to APCs. Key outcomes include a two step approach, first allow APC to collect information for past medication and later mark Active/Inactive medications.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <img src={require(`../Images/optum/web-wf-collection-1.png`)}  alt="" className="grid-image "/>
                        </div>
                    </div>
                </div>

                {/* Outcomes and Interface Suggestions */}
                
                <div className="container content-width-extra-large">
                    <h2 className="space-top-large">Component overview</h2>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentOverview} />
                        
                    </div> */}
                </div>
                <ImageSwiper images={componentOverview}  />


            </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Review of Systems</h5>
                    <h3>Researched faster execution of Review of Systems forms.</h3>
                    <p>The APCs have limited time allocation per member visit, this requires the system to leverage available time and onboard user data efficiently. 
                    </p>
                </div>

                {/* Research */}
                <div className="container content-width-extra-large">
                    {/* Unordered List  */}
                    <div className="panel panel-body content-width-extra-large space-top-medium">
                        <div className="space-bottom-small">
                            <h3>Research performed on Review of Systems data.</h3>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">Exploration of form data that will be collected from the member. </li>
                                <li className="bordered-list-item-small">What atoms of the design system can be leveraged to build a consistent and modular form? </li>
                                <li className="bordered-list-item-small">How to future-proof the system for different data collection processes. </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* Outcomes and Interface Suggestions */}
                <div className="container content-width-extra-large">
                    <h3 className="space-top-large">Outcomes and Interaction Suggestions</h3>
                    <p className="space-bottom-large">Created possible states derived from Optum’s design system Netra and helped developers build and test all component instances in storybook js. This resulted in overall perfection in interaction patterns across all forms in HouseCalls.</p>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentGroup} />
                    </div> */}
                </div>
                <ImageSwiper images={componentGroup}/>
            </div>
            
            {/* Final Deliverable */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Further details</h5>
                    <br></br>
                    <h3>Led the integration of rapid design systems across various HouseCalls applications and pages, driving notable improvements in design and code modularity. This initiative streamlined development processes, fostering efficiency and ensuring consistent user experiences.</h3>
                    <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>
        </div>
    )
};

export default Optum;