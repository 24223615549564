import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import YouTubePlayer from "../YoutubePlayer/YoutubePlayer";
import ImageSlider from "../ImageSlider";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";


const prototypeImages = [
    { src: require(`../Images/marysplace/web-midfid-proto-1.png`), alt: 'Image 1' },
    { src: require(`../Images/marysplace/web-midfid-proto-2.png`), alt: 'Image 2' },
    { src: require(`../Images/marysplace/web-midfid-proto-3.png`), alt: 'Image 3' },
];

const lowFidelityWireframes = [
    { src: require(`../Images/marysplace/web-wireframe-1.png`), alt: 'Image 1' },
    { src: require(`../Images/marysplace/web-wireframe-2.png`), alt: 'Image 2' },
];

function Marysplace(){

    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="marys-place-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Research with key stakeholders Sales enablement teams, product managers."
                text2="Wireframing and usability testing of hypotheses and delivery of mock-ups."
                text3="Establishing and implementing design system and mentoring 2 junior designers."
                text4="2 Product Manager, 2 Business Analysts"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, 2 Visual Designer"
                text7="Marysplace"
                text8="Marysplace"
                text9="Marysplace"
            >

            </ProjectHeroPanel>
            
            {/* Marys Place Project Overview  */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Overview</h5>
                    <h2>Honeycomb, is an app designed for Mary's Place field staff to efficiently manage resources.</h2>
                    <p>Mary's Place, Seattle is an non-profit that provides resources and shelter to vulnerable communities in King County to help them in their journey out of homelessness.</p>
                    <p>As of January 2020, Washington state had an estimated 22,923 residents experiencing homelessness on any given day. To address emerging needs for Mary's Place, Starbucks sponsored the research and development efforts as CSR efforts to curb homelessness.</p>
                </div>
            </div>
        
            {/* Mobile Screens  */}
            <div className="section">
                <div className="container grid-container">
                    <div className="layout-grid grid-thirds grid-direction-vertical-auto">
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-Home-screen.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>Dashboard provides easy monitoring of the marysplace performance.</h5>
                            </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-list-view.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>marysplace detailsallows easy manipulation of various status and stages during deal progress.</h5>
                            </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-resource-details-page.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>Offering details helps you negotiate easily with clients and change in data.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="layout-grid grid-thirds grid-direction-vertical-auto space-top-large">
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-maps-view.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>Maps enable users to quickly locate nearby resources, both near their current location and during outreach.</h5>
                            </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-maps-view.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>Clients details where users create profiles, use a scratch pad to document family needs, add resources & share/print from the app.</h5>
                            </div>
                        </div>
                        <div className="grid-halves grid-direction-vertical-auto">
                            <img src={require(`../Images/marysplace/mobile-add-resources-family.png`)}  alt="" className="rounded-image scale90"/>   
                            <div className="panel panel-body space-top-medium">
                                <h5>Resource onboarding is a form to onboard resources with granular details, a primary method to create enriched resources.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Timeline and User Research */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Timeline</h5>
                    <br></br> 
                    <img src={require(`../Images/marysplace/web-timeline.png`)}  alt="" className="rounded-image space-bottom-extra-large scale120"/>                      
                    <h5>Research</h5>
                    <h2>Structured research from reports, field studies and interviews</h2>
                    <h3>Stakeholder matrix created from annual reports data</h3>
                    <img src={require(`../Images/marysplace/web-stakeholder-matrix.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      

                    <h3>Current process</h3>
                    <img src={require(`../Images/marysplace/web-user-flow-1.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                    
                    {/* Unordered List  */}
                    <div className="panel panel-body content-width-extra-large">
                        <div className="space-bottom">
                            <h2 >Information gaps & research questions for primary research</h2>
                            <p>Thes questions were generated as the team's collective understanding of Mary's Place functioning and history. I choose the appropriate research tool moving forward to answer these questions.</p>
                            <ul className="list-unstyled">
                                <li className="bordered-list-item-small">Incomplete understanding of <span className="white">user flow.</span></li>
                                <li className="bordered-list-item-small"><span className="white">Intake vs Outreach</span> roles in the diversion process</li>
                                <li className="bordered-list-item-small">What <span className="white">resources</span> are families seeking from Mary's Place? </li>
                                <li className="bordered-list-item-small">How do Outreach Specialists <span className="white">document/share/manage resources?</span></li>
                                <li className="bordered-list-item-small">What happens after outreach and housing assessment?</li>
                                <li className="bordered-list-item-small">How <span className="white">eligilibility</span> is determined?</li>
                                <li className="bordered-list-item-small">How are families <span className="white">prioritized?</span></li>
                            </ul>
                        </div>
                    </div>

                    {/* Images and Information Panel */}
                    <div className="layout-grid grid-two-thirds space-top-extra-large">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <h3>Field study</h3>
                                    <p>We observed field research where a family of 10 comprised of 8 children & an injured head of household were living out of an SUV.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <img src={require(`../Images/marysplace/web-fr-car.jpg`)}  alt="" className="grid-image "/>
                        </div>
                    </div>

                    <div className="layout-grid grid-two-thirds space-top-extra-large">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <h3>SME semi- structured interview</h3>
                                    <p>Interviewed outreach team head on timelines, clients, demography, scheduling, etc. Managed interview & follow-up questions, aggregated questions.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <img src={require(`../Images/marysplace/web-fr-allen-1.jpg`)}  alt="" className="grid-image "/>
                        </div>
                    </div>

                    <div className="layout-grid grid-two-thirds space-top-extra-large">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <h3>Focus group</h3>
                                    <p>Focus groups were challenging due to the correctness of answers, sensitive info, and fear of judgment but managed well. Asked key questions to get resource categories & pain points.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <img src={require(`../Images/marysplace/web-fr-allen-2.jpg`)}  alt="" className="grid-image "/>
                        </div>
                    </div>

                    <div className="layout-grid grid-two-thirds space-top-extra-large">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                   <h3>Verifying our current understanding.</h3>
                                    <p>Specialists suggesting on the user flow diagram.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <img src={require(`../Images/marysplace/web-fr-allen-3.jpg`)}  alt="" className="grid-image "/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Triangulated Insights and analysis of research */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Triangulated Insights and analysis of research</h5>
                    <br></br>
                    <h2>An overview of research insights and methods.</h2>
                    <h3>Research findings and definig high-level categories via affinity mapping</h3>
                    <br></br> 
                    <img src={require(`../Images/marysplace/web-affinity-map.jpg`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                    
                    <h3>Redesigned the user flow diagram</h3>
                    <br></br> 
                    <img src={require(`../Images/marysplace/web-user-flow-2.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                </div>
            </div>

            {/* Conclusion */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Conclusion</h5>
                    <br></br>
                    <h2>Learnings and emerging design requirements</h2>       
                    <div className="layout-grid grid-thirds space-bottom-medium">
                        <div className="panel-body panel fixed-height-panel">
                            <div className="extend-elements-vertical">
                                <h5>Multiple locations, services and individual status.</h5>
                                <p className="para-nospace">Reflect on provider-services hierarchy.</p>
                            </div>
                        </div>
                        <div className="panel-body panel fixed-height-panel">
                            <div className="extend-elements-vertical">
                                <h5>Refined knowledge base in silos.</h5>
                                <p className="para-nospace">Mechanism to "favorite" resources.</p>
                            </div>
                        </div>
                        <div className="panel-body panel fixed-height-panel">
                            <div className="extend-elements-vertical">
                                <h5>Resources sharing through text or physical artifacts.</h5>
                                <p className="para-nospace">Direct sharing from the solution or print details.</p>
                            </div>
                        </div>    
                    </div>
                    <div className="layout-grid grid-thirds">
                        <div className="panel-body panel fixed-height-panel">
                            <div className="extend-elements-vertical">
                                <h5>Resources status is volatile.</h5>
                                <p className="para-nospace">Vetting process or regular audits on resources.</p>
                            </div>
                        </div>
                        <div className="panel-body panel fixed-height-panel">
                            <div className="extend-elements-vertical">
                                <h5>Frequent help offered from other organizations and MOUs.</h5>
                                <p className="para-nospace">Distinct labeling for partners</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Ideation */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Ideation</h5>
                    <br></br>
                    <h2>Design Question</h2>
                    <div className="panel bg-gradient-8">
                        <div className="panel-body space-bottom-extra-large">
                            <h2 className="no-bottom-space">How might we create a tool for the outreach specialist to manage disparate resources and assist clients throughout the outreach process? </h2>
                        </div>
                    </div>
                    <h3>Brainstorming session</h3>
                    <img src={require(`../Images/marysplace/web-brain-storming-1.jpg`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                    <h3>Low-fidelity Wireframes</h3>
                    <div className="space-bottom-extra-large">
                        <ImageSlider images={lowFidelityWireframes} />
                    </div>
                    
                    {/* Slider to be inserted here */}

                    <h3>Usability Testing on medium-fidelity wireframes</h3>
                    <p>We evaluated primary tasks create, edit, browse, filters, and share of resources. I constructed the test plan, scenario and evaluation strategy. Firstly, I made sure the script was intact  and we structured post-task interviews with individual users.</p>   
                    <h4>Usability Session 1</h4>
                    <h5>Prominent issues that lead to a 50% task success rate.</h5>
                    <ul>
                        <li>Nomenclature - Provider and Services hierarchy didin't match the mental model of users.</li>
                        <li>Maps - Issues with combining maps and resources list, users expected maps as differentiating factor.</li>
                    </ul>
                    <div className="space-bottom-small">
                        <ImageSlider images={prototypeImages} scaleImageFactor={"scale120"}/>
                    </div>
                    
                </div>
            </div>

            {/* Final Deliverable */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Final Deliverable</h5>
                    <br></br>
                    <h2>Application Walkthrough</h2>
                    <h5>User testing on software prototype</h5>
                    <ul>
                        <li>Created new plan for usability session 2, eliminating errors caused by cosmetic issues(e.g. favorite button) and redundant nomemclature (e.g. lists and provider-services, now called resources</li>
                        <li>Test focused on end-to-end task accomplishments with team of 6, including previous and new testers. Task sucess rate was 100%.</li>
                    </ul>

                    <div className="layout-grid grid-two-thirds space-top-extra-large">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <h3>The app walkthrough for scenario below.</h3>
                                    <p>Users are in the middle of outreach engaging with a family of 5.</p>
                                    <br></br>
                                    <p>Upon arriving, the family expresses that they need help finding several resources like employment, translation, and food.</p>
                                    <br></br>
                                    <p>Let’s explore how Honeycomb can help.</p>
                                </div>                          
                            </div>
                        </div>
                        <div className="panel">
                            <YouTubePlayer videoId="NYZkuI-w8rg"/>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Reflections */}
            <div className="panel panel-body content-width-extra-large">
                        <div className="space-bottom">
                            <h5>Reflections</h5>
                            <h3>What could have been better?</h3>
                            <ul className="list-unstyled">
                                <li>- I could have created a scalable design system for any teams that work on the project in the future. </li>
                                <li>- Additional visual assests to make the application appealing, explorations into empty states and other edge cases.</li>
                            </ul>
                            <h3>What went well?</h3>
                            <ul className="list-unstyled">
                                <li>- The correct blend of the team with prior experience in delivering products was the best mixture for this project, thus balancing discussions and delivery.</li>
                                <li>- Through research and access to the Allen family center and outreach specialists.</li>
                            </ul>
                            <h3>Challenges we faced?</h3>
                            <ul className="list-unstyled">
                                <li>- Some ket challenges were understanding the internal language they associate while at work.</li>
                                <li>- While resources onboarding is easy, the access can be granted to anyone, but we found out later that it might compromise the resource intergrity this reducing user base.</li>
                            </ul>
                        </div>
                    </div>
            
            {/* Final Deliverable */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Further details</h5>
                    <br></br>
                    <h2>Multiple pivots, specific testing plans, research strategy, design system and more to share.</h2>
                    <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>

        </div>
        
    )
}

    export default Marysplace;