import PropTypes from "prop-types";
import "./components.css";
import React, {useState} from "react";
import lock from './Images/lock.png';


 const Button = ({ label, size="lg", onClick, fontWeight="semibold", btncolor, className, secure  }) => {
    const btnClass = `button ${size} inter-font ${fontWeight}`;

    //Use state to track the hover state of the button
    const [isHover, setIsHover] = useState(false);

    return (
        <button className={`${btnClass} ${className}`} 
                onClick={onClick} 
                style={{backgroundColor: isHover ? "#646464" : btncolor}}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                >
            {secure && <img src={lock} alt="lock"/> }{secure && <span style={{ marginRight: '8px' }}></span>} {label}
        </button>
    );
 };


//Define prop types for Button

Button.propTypes = {
    label: PropTypes.string,
    size: PropTypes.oneOf(["sm","md","lg"]),
    fontWeight: PropTypes.string,
    btncolor: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.element
}

 export default Button;