import React from 'react';
import Badge from '../Badge/Badge';

const Gimmick = () => {
    return (
        <>
        <div className="section no-top-space ">
                <div className='container content-width-extra-large'>
                    <h1>Gimmick</h1>
                    <p>Exploring Futuristic Design: <span className='white'> 3D Objects Using Fusion 360 and Spline</span></p>
                </div>
        </div>

        <div className="section">
                <div className="container content-width-extra-large space-top-extra-large">
                    <h5>Fusion 360 Samples</h5>
                </div>
                
                {/* Fusion Sample 1 */}
                <div className="container content-width-extra-large space-top-extra-large">
                    <h2 className='space-bottom-small'>Reminder Box</h2>
                    <div className="container-badge">
                                <Badge label="Fusion 360" badgeColor="bg-gradient-4"/>
                                <Badge label="Hardware Prototyping" badgeColor="bg-gradient-4"/>
                                <Badge label="3D Printing / Ultimaker" badgeColor="bg-gradient-4"/>        
                    </div>
                    <p className='space-top-small'>Attachment for trash to remind shared responsibillities for roommates</p>
                </div>    
                <div className="container content-width-extra-large space-bottom-extra-large">
                    <img src={require(`../Images/gimmick/Reminderbox.png`)}  alt="" className="rounded-image"/>   
                </div>

                {/* Fusion Sample 2 */}
                <div className="container content-width-extra-large space-top-extra-large">
                    <h2 className='space-bottom-small'>Whack-a-mole for Cognitive Assessment</h2>
                    <div className="container-badge">
                                <Badge label="Fusion 360" badgeColor="bg-gradient-4"/>
                                <Badge label="Hardware Prototyping" badgeColor="bg-gradient-4"/>
                                <Badge label="Raspberry Pi 4" badgeColor="bg-gradient-4"/>        
                                <Badge label="IoT" badgeColor="bg-gradient-4"/>        
                    </div>
                    <p className='space-top-small'>Attachment for trash to remind shared responsibillities for roommates</p>
                </div>    
                <div className="container content-width-extra-large space-bottom-extra-large">
                    <img src={require(`../Images/gimmick/whack-a-mole.png`)}  alt="" className="rounded-image"/>   
                </div>
        </div>

        <div className="section">
                <div className="container content-width-extra-large space-top-extra-large">
                    <h5>Spline 3D</h5>
                </div>
                
                {/* Spline 3D */}
                <div className="container content-width-extra-large space-top-extra-large">
                    <h2 className='space-bottom-small'>Textures and Model Development in Spline</h2>
                    <div className="container-badge">
                                <Badge label="Textures" badgeColor="bg-gradient-4"/>
                                <Badge label="Animation" badgeColor="bg-gradient-4"/>
                    </div>
                    <p className='space-top-small'>Modeled and textured using Spline 3D, tool based on three.js</p>
                </div>    
                <div className="container content-width-extra-large space-bottom-extra-large">
                    <video height="auto" width="100%" src={require(`../Images/gimmick/current-flow.mp4`)} autoPlay className='rounded-image'/>
                    
                </div>
        </div>
        </>                  
    );
};

export default Gimmick;
