import React, { useState } from "react";
//import Badge from "../Badge/Badge";
import cardData from "../../cardData";
import ProjectCards from "../Cards/ProjectCards";
import Button from "../Button";
import {motion, useTransform, useScroll } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import "../components.css";

import optumLogo from '../Images/optum-logo-white.png';
import uwLogo from '../Images/uw-logo-white.png';
import tcsLogo from '../Images/tcs-logo-white.png';
import profilePhoto from '../Images/profile-photo.png';
import skill1 from '../Images/skill-1.png';
import skill2 from '../Images/skill-2.png';
import skill3 from '../Images/skill-3.png';
import skill4 from '../Images/skill-4.png';
import skill5 from '../Images/skill-5.png';
import skill6 from '../Images/skill-6.png';


const Work = () => {
    const[showModal, setShowModal] = useState(false);
    const[selectedProject,setSelectedProject] = useState(false);
    const[password, setPassword] = useState("");
    const[error, setError] =useState("");
    const navigate = useNavigate();
    //Hardcoded password
    const correctPassword = "@Recruiter2024";

    //Modal logic
    const openModal = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setError("");
        setPassword("");
    }

    const handleSubmit = () => {
        if(password === correctPassword){
            //Proceed with accessing new project
            //For now, let's just close the modal
            navigate(`/project/${selectedProject}`);
            closeModal();
        } else {
            setError("Please enter correct password");
        }
    }

    

    const projectCards = cardData.map(x => {
        return (<ProjectCards
            key={x.id}
            //projectData={x}
            id={x.id}
            image={x.image}
            panelSubheading={x.panelSubheading}
            cardHeading={x.heading}
            cardSubheading={x.subheading}
            badgesArray={x.badges}
            secureStatus={x.secure}
            openModal={openModal}
            onClick={() => {
                if(x.secure){
                    openModal(x);
                }else {
                        return null;
                    }
                
            }}
      />)})

        return(<div>
                    {/* New Hero */}
                        <motion.div 
                        initial={{  opacity: 0 }}
                        animate={{  opacity: 1 }}
                        transition={{  ease:"easeOut", duration:3 }}
                        className="hero-gradient-background">
                        </motion.div>
                        <motion.div 
                            initial={{ y:+50, opacity: 0 }}
                            animate={{ y:0, opacity: 1 }}
                            transition={{  ease:"easeOut", duration:0.5 }}
                            className="hero-section">
                                <div className="container">
                                
                                    <div className="hero-vertical-line"/>
                                    <div className="profile-holder space-bottom-small">
                                        <img src={profilePhoto} width="auto" height="auto" alt="brand-logo" />
                                    </div>
                                    <div className="hero-content">
                                    <h7>DESIGN X CODE</h7>
                                    {/* <div className="container-badge">
                                        <Badge label="Design X Code" badgeColor="bg-gradient-4"/> 
                                        <Badge label='Built using React and Storybook' badgeColor='gray-2'/>   
                                    </div> */}
                                    <h3 className="space-top-medium">I infuse design into usable interfaces and believe that<br/>impactful design excels in the subtle interaction<br/>between people, culture, and technology.</h3>
                                    {/* <p className="">Over the past eight years, I have tackled complex challenges in large-scale enterprise applications and hardware/software integrations using a systems approach to design. I have experience building and implementing design systems at scale, along with technical expertise.</p> */}
                                    <h7>BRANDS AND IMPACT</h7>
                                    <div className="space-top-medium space-bottom-large">
                                        <img src={optumLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo" />
                                        <img src={uwLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo"/>
                                        <img src={tcsLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo"/>
                                    </div>
                                    <div className="hero-container grid-container ">
                                        <div className="hero-panel">
                                            <img src={skill1} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Human-Computer Interaction</h6>
                                        </div>
                                        <div className="hero-panel">
                                            <img src={skill2} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Systems Thinking</h6>
                                        </div>
                                        <div className="hero-panel">
                                            <img src={skill3} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Interaction Design</h6>
                                        </div>
                                        <div className="hero-panel">
                                            <img src={skill4} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Design Systems</h6>
                                        </div>
                                        <div className="hero-panel">
                                            <img src={skill5} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Designer-Developer Collaboration</h6>
                                        </div>
                                        <div className="hero-panel">
                                            <img src={skill6} width="auto" height="auto" alt="brand-logo"/>
                                            <h6>Design Technologist</h6>
                                        </div>
                                        </div>
            
                                    </div>

                                </div>
                        </motion.div>
                    
                    <motion.div
                        initial={{ y:+50, opacity: 0 }}
                        animate={{ y:0, opacity: 1 }}
                        transition={{  ease:"easeOut", duration:0.5 }}
                    >
                    {projectCards}
                    </motion.div>

                    {/* Modal Content  */}
                    { selectedProject && showModal &&
                        <div className="modal">
                            <div className="modal-content">
                                <span className="close" onClick={closeModal}>&times;</span>
                                <h2>Password protected</h2>
                                <p>This project is password protected, please enter password</p>
                                
                                <div>
                                    <div className="input-button-row">
                                        <input type="password" text="password" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter password" />
                                        <Button size="md" label="Submit" onClick={handleSubmit} className="primary-4"/>    
                                    </div>
                                </div>
                                {/* <button onClick={handleSubmit}>Submit</button> */}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                        </div>
                    }

                </div>
        )       
     };  
  export default Work;