import React from 'react';
import Badge from "../Badge/Badge";
import optumLogo from '../Images/optum.png';
import tcsLogo from '../Images/tcs.png';
import { Link } from 'react-router-dom'; 

const AboutMe = () => {
    return (
       <div>
            <div className="section no-top-space no-bottom-space">
                <div className="container grid-container" >
                    <div className="layout-grid grid-halves">
                        <div >
                            <img src={require(`../Images/profile-image.jpg`)}  alt="" className="rounded-image"/>
                        </div> 
                        <div className="panel">
                            <div className="panel-body">
                                <div className="extend-elements-vertical">
                                    <div>
                                        <h4>About me</h4>
                                        <p>I pursued master's degree in <span className='white'>Technology Innovation with a specialization in Human-Computer Interaction at the University of Washington's Global Innovation Exchange.</span> I have over eight years of experience designing CRM, internal tools, digital libraries for Tata Consultancy Services.</p>
                                        <p>I craft interfaces and interactions and aim to pursue <span className='white'>product design for dev tools, APIs, and technical tools.</span> My skill set includes implementing complex user workflows, using advanced user-centric methodologies, and designing next-generation tools.</p>
                                        <p>I observe people, culture, and technology through the lens of systems thinking, which gives me a broad contextual awareness and enables me to thrive in ambiguous situations.</p>
                                        <p>I am also deeply spiritual and serve as a faculty for <span className='white' ><Link target='blank' to="https://www.skycampushappiness.org/">SKY campus programs</Link></span>, where I conduct breathwork & meditation programs. I enjoy exploring background scores from movies & games.</p>
                                        <p>Let’s connect or drop a mail at <span>abhi.saraf25@gmail.com</span></p>
                                    </div>
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            {/*Work experience list and skills*/}
            <div className='container no-top-space'>
                <h4>Skills</h4>
                <div className="panel">
                    <div className="panel-body">
                        <div className="extend-elements-vertical">
                            <div>
                                <div className='container-badge'>
                                    <Badge label="Interaction Design" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Human-Computer Interaction" badgeColor="bg-gradient-4"/> 
                                    <Badge label="User Research" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Front-end Development" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Design Systems" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Systems Thinking" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Design Thinking" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Prototyping" badgeColor="bg-gradient-4"/> 
                                    <Badge label="Information Architecture" badgeColor="bg-gradient-4"/> 
                                </div>
                            </div>
                        </div>                          
                    </div>
                </div>
            </div>
            <div className='section no-top-space'>
                <div className="container grid-container space-top-medium">
                    <h4>Work Experience</h4>
                    {/* Optum Work experience */}
                    <div className='layout layout-grid grid-two-thirds'>
                        <div>
                            <div className='panel-body'>
                                <div>
                                    <img src={optumLogo} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                    <p><span className='white'>Optum | UX Designer | 2022-Present</span></p>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panel-body">
                                <h5>HouseCalls</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">Designing data collection systems for clinical teams that record patients’ vitals online and offline on handheld devices during house visits.</li>
                                    <li className="bordered-list-item-small">Advancing the design team’s efficiency on by creating reusable components derived from Netra Design System for faster delivery in workflows.</li>
                                    <li className="bordered-list-item-small">Fostering the use of clickable prototypes for clarity in designer, developer, and product management collaboration with the clinical team.</li>
                                    <li className="bordered-list-item-small">Researching along with clinical teams to discover and design faster methods of data collection, performing usability testing with Advanced Practitioner Care team.</li>
                                    <li className="bordered-list-item-small">Currently designing Optum HouseCalls and Client Implementation Engine.</li>
                                </ul>
                                
                                <h5>Client Implementation Engine</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">Spearheaded the design of information architecture for new clients/partners at Optum.</li>
                                    <li className="bordered-list-item-small">Crafted user onboarding flows, design layouts, dashboards, and approval processes that seamlessly integrate the Client Implementation Engine across various applications.</li>
                                    <li className="bordered-list-item-small">Developed components contextualizing the Client Implementation Engine to ensure a cohesive user experience.</li>
                                    <li className="bordered-list-item-small">Resolved ambiguous challenges regarding data saving methods at both page and application levels through close collaboration with product management and development teams.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* TCS Work Experience */}
                    <div className='layout layout-grid grid-two-thirds'>
                        <div>
                            <div className='panel-body'>
                                <div>
                                    <img src={tcsLogo} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                    <p><span className='white'>Tata Consultancy Services | Senior UX Designer | 2015-2021</span></p>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panel-body">
                                <h5>SmartSales CRM</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">Designed TCS CRM to cater to special needs for large-value deals, tracking lead progress, easy monitoring of revenue pipelines, and collaboration.</li>
                                    <li className="bordered-list-item-small">Conducted E2E research through interviews, cognitive walkthroughs, surveys, and data analysis in partnership with sales enablement team to create research-informed artifacts like information architecture, journey maps, and cross-app API needs.</li>
                                    <li className="bordered-list-item-small">Designed E2E assets in form of clickable prototypes, usability test plans, and standardized design systems for the mobile and desktop app's future expansion. Onboarded and mentored 2 UX Designers for upcoming project phases.</li>
                                    <li className="bordered-list-item-small">Helped onboard +3000 core sales users and their teams enabling them to manage +$15Bn worth of deals on the system.</li>
                                </ul>
                                
                                <h5>PacePort</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">The research thought leadership lacked visibility of artifacts like patents, whitepapers, and through leaders during customer visits to TCS premises.</li>
                                    <li className="bordered-list-item-small">I led the design of the Pace Port digital library that projects research to visitors on multiple form factors like Touch screen TV, web, and mobile, through rapid design and testing, and leveraging existing pattern library.</li>
                                    <li className="bordered-list-item-small">Bridged the knowledge gap between researchers and audience, growing visibility to 600,000 employees.</li>
                                </ul>

                                <h5>Mondelez</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">Mondelez chocolate factory has an established SAP S/4/HANA practice for managing the material flow on ERP and occasionally suffers from data monitoring, we solved the issue through a digital twin model offering by mimicking a digital version of the manufacturing plant unit.</li>
                                    <li className="bordered-list-item-small">Improved the efficiency of the plant staff through remote monitoring on the cloud-based digital interface. To gain insights I conducted unstructured research through a cognitive walkthrough and interviews with PM and delivery owners delivering a long-term interface requirement roadmap.</li>
                                    <li className="bordered-list-item-small">Delivered a POC by extracting elements of the Vue.js design system and created a clickable prototype and presented it to Mondelez leadership. This led to an opportunity conversion of $500K.</li>
                                </ul>

                                <h5>Software Lifecycle Management</h5>
                                <ul className="list-unstyled">
                                    <li className="bordered-list-item-small">Piled licenses and redundant availability for paid software were an issue, we designed an infrastructure tool where employees could request, transfer, and surrender licenses eventually leading to a 15% reduction in software expenses.</li>
                                    <li className="bordered-list-item-small">I partnered with the product owners and conducted semi-structured interviews for the infrastructure teams. Analysing and brainstorming led to features like an agreement repository, and available licenses schedule.</li>
                                    <li className="bordered-list-item-small">The emergent features were delivered through wireframes evaluated with structured usability tests. Also did dev hand-off for high-fidelity responsive artifacts.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
