import './App.css';
import Nav from './components/Navigation/Nav';
import Work from './components/Work/Work';
import Gimmick from './components/Gimmick/Gimmick';
import Resume from './components/Resume/Resume';
import Aboutme from './components/Aboutme/Aboutme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Smartsales from './components/Project/Smartsales';
import Optum from './components/Project/Optum';
import Paceport from './components/Project/Paceport';
import Marysplace from './components/Project/Marysplace';
import Vocalamp from './components/Project/Vocalamp';
import WackAMole from './components/Project/WackAMole';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
import Decorator from './components/Decorators';
import { motion, useScroll, useSpring } from 'framer-motion';
//import HeroGradient from './components/HeroGradient';

//import { useEffect } from 'react';

function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 25,
    restDelta: 0.001
  });

  // useEffect(() => {
  //   const handleScroll = () => {
  //     console.log(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <>
      <motion.div className="progress-bar" style={{ scaleX }} />
      
      {/* <HeroGradient /> */}
      <Decorator />
      <ScrollToTopButton />
      <Router basename="/">
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/gimmick" element={<Gimmick />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/project">
            <Route path="/project/optum" element={<Optum />} />
            <Route path="/project/smartsales" element={<Smartsales />} />
            <Route path="/project/paceport" element={<Paceport />} />
            <Route path="/project/marysplace" element={<Marysplace />} />
            <Route path="/project/vocalamp" element={<Vocalamp />} />
            <Route path="/project/whackamole" element={<WackAMole />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
