import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Badge from '../Badge/Badge';
import Button from '../Button';
import "./Footer.css";
import "../components.css";
import emailIcon from '../Images/email.png';
import githubIcon from '../Images/github.png';
import linkedinIcon from '../Images/linkedin.png';
import logoImage from '../Images/140x140.png';

const Footer = () => {
    const[showModal, setShowModal] = useState(false);
    const[password, setPassword] = useState("");
    const[error, setError] =useState("");
    const navigate = useNavigate();
    //Hardcoded password
    const correctPassword = "@Recruiter2024";

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setError("");
        setPassword("");
    }

    const handleSubmit = () => {
        if(password === correctPassword){
            //Proceed with accessing new project
            //For now, let's just close the modal
            navigate(`project/optum`);
            closeModal();
        } else {
            setError("Please enter correct password");
        }
    };

    return(
        <div>
            <div className='container'>
                <div className='panel'>
                    {/* Logo and Project Row */}
                    <div className='panel-body'>
                        <div className='footer-upper-row'>
                            <div>
                                <a href="/" aria-current="page" className="" aria-label="home">
                                    <img src={logoImage} width="auto" height="auto" alt="web-logo" className="scale120" />
                                </a>
                                <a href="/" aria-current="page" aria-label="home">
                                    <h4 className='space-top-small no-bottom-space'>Abhijeet Saraf</h4>
                                    <h5>Design Portfolio</h5>  
                                    <Badge label='Built using React and Storybook' badgeColor='gray-1'/>
                                </a>
                            </div>
                            <div>
                                <ul className='list-unstyled'>
                                    <li><h4>Work</h4></li>
                                    <li><Link className="text-link space-bottom-small" onClick={openModal}>Optum HouseCalls</Link></li>
                                    <li><Link className="text-link space-bottom-small" to="/project/smartsales">SmartSales Opportunity</Link></li>
                                    <li><Link className="text-link space-bottom-small" to="/project/paceport">PacePort Digital Library</Link></li>
                                    <li><Link className="text-link space-bottom-small" to="/project/marysplace">Mary's Place, Seattle</Link></li>
                                    <li><Link className="text-link space-bottom-small" to="/project/vocalamp">Vocalamp</Link></li>
                                    <li><Link className="text-link space-bottom-small" to="/project/whackamole">Whack-a-mole</Link></li>
                                </ul>

                            </div>
                            
                        </div>
                    </div>
                    
                    
                    {/* Copyright and Link */}
                    <div className='panel-body'>
                        <div className='footer-lower-row'>
                            <div>
                                <p>&copy; Copyright Abhijeet Saraf</p>
                            </div>
                            <div>
                                <a href="mailto:abhi.saraf25@gmail.com" target='blank' aria-current="page" className="footer-image" aria-label="home">
                                    <img src={emailIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                </a>
                                <a href="https://github.com/abhijeetsaraf" target='blank' aria-current="page" className="footer-image" aria-label="home">
                                    <img src={githubIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                </a>
                                <a href="https://www.linkedin.com/in/abhijeet-saraf/" target='blank' aria-current="page" className="footer-image" aria-label="home">
                                    <img src={linkedinIcon} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Content  */}
            { showModal &&
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Password protected</h2>
                        <p>This project is password protected, please enter password</p>
                        
                        <div>
                            <div className="input-button-row">
                                <input type="password" text="password" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter password" />
                                <Button size="md" label="Submit" onClick={handleSubmit} className="primary-4"/>    
                            </div>
                        </div>
                        {/* <button onClick={handleSubmit}>Submit</button> */}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            }
        </div>
    )
}

export default Footer;