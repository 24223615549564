import React from 'react';
import "./components.css";

const Decorator = () => {
    return (
    <div className='vertical-border-container'>
        <div className='vertical-border-wrapper'>            
            {/* <div className='border-position-fixed-left'>
                <div className='border-left'></div>
            </div>
            <div className='border-position-fixed-right'>
                <div className='border-right'></div>
            </div> */}
            <div className='vertical-border-left'/>
            <div className='vertical-border-right'/>
        </div>
    </div>
    );
};

export default Decorator;