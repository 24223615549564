import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";

function Paceport(){

    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="pace-port-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Extended TCS Brand identity to create a design system."
                text2="Quick mock-ups using design system on existing UX patterns to conceptualize digital library."
                text3="Improved checkout flows drawing inspiration from e-commerce patterns."
                text4="CTO Office Project manager"
                text5="30-35 Developer and QA resources"
                text6="4 Front-end dev, 1 Scrum master, 1 UX designer"
                text7="2019 - 2020"
                text8=""
                text9=""
            >
            </ProjectHeroPanel>

            {/* PacePort Project Overview */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Overview</h5>
                    <h2>Digital library for TCS Pace Ports.</h2>
                    <p className="space-bottom-extra-large">Cornell Tech is a partner institute for TCS funded through Tata Group of companies. Pace Port is an incubation center located at the Cornell Tech. This product occupy a corner that showcase visitors TCS branding and IP on a 55'in touch interface.</p>
                 </div>
            </div>

            {/* Pace Port Interface Images */}
            <div className="section">
                <div className="container content-width-extra-large">
                
                {/* Dashboard */}
                <h3 className="no-bottom-space">Dashboard for clients and visitors showcased on a 55'in touch interface.</h3>
                <img src={require(`../Images/paceport/web-dashboard.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                    
                {/* Publication and Research */}
                <h3 className="no-bottom-space">Showcasing IP legacy of TCS. Top publication from research leaders and collaborations.</h3>
                <img src={require(`../Images/paceport/web-researchleaders.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                          
                   
                {/* Information Hierarchy */}
                <h3 className="no-bottom-space">Information hierarchy that allows users to deep dive into artifacts of interest.</h3>
                <img src={require(`../Images/paceport/web-patents.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      
                
                {/* Checkout Flows */}
                <h3 className="no-bottom-space">Checkouts, if users want to read about research on their personal devices.</h3>
                <img src={require(`../Images/paceport/web-checkouts.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>                      

                {/* Visitor IDs */}
                <h3 className="no-bottom-space">Resolved manual input issues for mail id and other details through visitor ID cards (RFID).</h3>
                <img src={require(`../Images/paceport/web-login-autofil.png`)}  alt="" className="rounded space-bottom-extra-large-image"/>                      
                </div>
            </div>

            {/* Final Deliverable */}
            <div className="section">
                <div className="container content-width-extra-large">
                    <h5>Further details</h5>
                    <br></br>
                    <h3>PacePort Digital Library is designed to provide high visibility of research capabilities during client visits.</h3>
                    <p>Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>


        </div>
    )
}

export default Paceport;